import { combinePaths } from "@/core/helpers/router.helpers";

export const authBaseRoute = "";
export const registrationRouteName = "registration";
export const loginRouterName = "login"
export const passwordChangeRouterName = "passwordchange"

export const registrationRoute = combinePaths(authBaseRoute, registrationRouteName);
export const loginRoute = combinePaths(authBaseRoute, loginRouterName);
export const passwordChange = combinePaths(authBaseRoute, passwordChangeRouterName)


