import { ProjectTabsEnum } from '@/typing/enums/project-tabs.enum'
import { IContext, } from '@/typing/interfaces/domain/context.interface'
import React, { createContext,  useState } from 'react'


export const Context = createContext<IContext| undefined>(undefined)

export const ContextProvider = ({ children }: any) => {
  const [activeTab, setActiveTab] = useState(ProjectTabsEnum.Block)

  const contextValue: IContext = {
    activeTab,
    setActiveTab,
  }

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}
