import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Store } from '@/typing/interfaces/system/store.interface';
import { authReducer } from './auth/reducer';
import { accountReducer } from './account';
import { navigationReducer } from './navigation';
import { favoriteProjectReducer } from './favorite-project'
import { sharedReducer } from './shared';

const rootReducer = combineReducers<Store.Root>({
  account: accountReducer,
  auth: authReducer,
  navigation: navigationReducer,
  favoritesProjects: favoriteProjectReducer,
  shared:  sharedReducer,
  
});

export type RootState = ReturnType<typeof rootReducer>;
export const store = createStore(rootReducer, composeWithDevTools());
