import { StoreActionKey } from "@/typing/enums/store-action-key.enum";
import { Store } from "@/typing/interfaces/system/store.interface";

export class SaveViewLookBlock implements Store.Action {
	readonly type = StoreActionKey.SAVE_VIEW_LOOK_BLOCK;
	constructor(
		public readonly payload: { viewLookBlock: "list" | "detailed" }
	) {}
}


export class SaveModeViewBlock implements Store.Action {
	readonly type = StoreActionKey.SAVE_MODE_VIEW_BLOCK;
	constructor(
		public readonly payload: { modeViewBlock: boolean }
	) {}
}



export type TSharedActions = SaveViewLookBlock | SaveModeViewBlock ;
