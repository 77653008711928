import { ITokenPair } from '@/typing';
import { StoreActionKey } from '@/typing/enums/store-action-key.enum';
import { Store } from '@/typing/interfaces/system/store.interface';

export class SaveTokens implements Store.Action {
  [x: string]: any;
  readonly type = StoreActionKey.SAVE_TOKENS;
  constructor(public readonly payload: ITokenPair) {}
}

export class SaveAccessToken implements Store.Action {
  readonly type = StoreActionKey.SAVE_ACCESS_TOKEN;
  constructor(
    public readonly payload: {
      accessToken: string;
    },
  ) {}
}

export class ResetTokens implements Store.Action {
  readonly type = StoreActionKey.RESET_TOKENS;
}

export type TAuthActions = SaveTokens | SaveAccessToken | ResetTokens;
