import { createReducer } from '@bitalikrty/redux-create-reducer';
import { TAuthActions } from './types';
import { StoreActionKey } from '@/typing/enums/store-action-key.enum';
import { Store } from '@/typing/interfaces/system/store.interface';

const initialState: Store.States.Auth = {
  accessToken: null,
  refreshToken: null,
};

export const authReducer = createReducer<Store.States.Auth, TAuthActions>(initialState, {
  [StoreActionKey.SAVE_TOKENS]: (state, action) => {
    return {
      ...state,
      accessToken: action.payload.accessToken,
      refreshToken: action.payload.refreshToken || state.refreshToken,
    };
  },

  [StoreActionKey.SAVE_ACCESS_TOKEN]: (state, action) => {
    return {
      ...state,
      accessToken: action.payload.accessToken,
    };
  },

  [StoreActionKey.RESET_TOKENS]: () => {
    return initialState;
  },
});
