import { Service } from "@/core/abstract";
import { SaveModeViewBlock, SaveViewLookBlock } from "@/store/shared";
import { storageService } from "../system";

class BlockService extends Service {
	public async initViewContentBlock() {
		const viewBlockState = storageService.get("viewLookBlockTab");
	    const modeViewBlock = storageService.get('modeViewBlockTab');

		const viewLookBlock = viewBlockState === "list"  ? "list" : "detailed";
		const isModeViewBlock = modeViewBlock === 'true' ?  true : false
        storageService.remove("blockId");
		this.dispatch(new SaveViewLookBlock({ viewLookBlock }));
		this.dispatch(new SaveModeViewBlock({  modeViewBlock: isModeViewBlock }));
	}

	public async toggleViewLookBlock(viewLookBlock: "list" | "detailed") {
		storageService.set("viewLookBlockTab", viewLookBlock);
		storageService.remove("blockId");
		this.dispatch(new SaveViewLookBlock({ viewLookBlock }));

	}

	public async toggleModeViewBlock(isModeViewBlock: boolean) {
		storageService.set('modeViewBlockTab', `${isModeViewBlock}`);

		this.dispatch(new SaveModeViewBlock({  modeViewBlock: isModeViewBlock }));

	}
}

export const blockService = new BlockService();
