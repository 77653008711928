import { GetPresignedUrlReqResponse } from '@/services/system/file.service';
import { http } from '../http.service';
import {  IGetAccountResponse,  IUpdateAccountPayload } from './interfaces'

export const accountReq = () => {
  return http.get<IGetAccountResponse>('/account');
};

export const patchAccountReq = (payload: IUpdateAccountPayload) => {
  return http.patch<IGetAccountResponse>('/account', payload)
}

