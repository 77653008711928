export enum StoreActionKey {
  SAVE_TOKENS = "SAVE_TOKENS",
  SAVE_ACCESS_TOKEN = "SAVE_ACCESS_TOKEN",
  RESET_TOKENS = "RESET_TOKENS",

  SET_ACCOUNT = "SET_ACCOUNT",
  SET_ACCOUNT_LOADING = "SET_ACCOUNT_LOADING",
  RESET_ACCOUNT = "RESET_ACCOUNT",

  SET_NAVIGATION_GROUP = "SET_NAVIGATION_GROUP",

  SIDE_BAR = "SIDE_BAR",

  SAVE_FAVORITE_PROJECT = "SAVE_FAVORITE_PROJECT",

  SAVE_VIEW_LOOK_BLOCK = "SAVE_VIEW_LOOK_BLOCK",
  SAVE_MODE_VIEW_BLOCK = "SAVE_MODE_VIEW_BLOCK",
}
