import { getFavoriteProjectsReq, putFavoriteProjectReq } from "@/api/projects";
import { Service } from "@/core/abstract";
import { store } from "@/store";
import { SaveFavoriteProject } from "@/store/favorite-project";
import { getFavoriteProject } from "@/store/favorite-project/selectors";
import { message } from "antd";


class FavoriteProjectService extends Service {
	public async toggleFavoriteProject(projectId: number) {
		try {
			const favoritesProjects = getFavoriteProject(store.getState());

			const isFavoriteProject = favoritesProjects.some(
				(it) => it.projectId === projectId
			);

			await putFavoriteProjectReq({
				action: !isFavoriteProject,
				projectId: projectId,
				data: {},
			});

			this.getFavoritesProjects();

			!isFavoriteProject ? 
			message.success("Project has been added to favorites") : message.success("The project has been removed from favorites") 
		} catch (error) {
			message.error(error.data.message);
		}
	}

	public async getFavoritesProjects() {
		try {
			const { data: favoritesProjects } = await getFavoriteProjectsReq();

			this.dispatch(new SaveFavoriteProject({ favoritesProjects }));
		} catch (error) {
			console.log(error.data.message);
		}
	}
}

export const favoriteProjectService = new FavoriteProjectService();
