import { IFavoriteProject } from "@/typing";
import { StoreActionKey } from "@/typing/enums/store-action-key.enum";
import { Store } from "@/typing/interfaces/system/store.interface";

export class SaveFavoriteProject implements Store.Action {
	readonly type = StoreActionKey.SAVE_FAVORITE_PROJECT;
	constructor(
		public readonly payload: { favoritesProjects: IFavoriteProject[] }
	) {}
}


export type TAccountActions = SaveFavoriteProject ;
