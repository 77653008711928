import { StoreActionKey } from "@/typing/enums/store-action-key.enum";
import { Store } from "@/typing/interfaces/system/store.interface";
import { createReducer } from "@bitalikrty/redux-create-reducer";
import { TAccountActions,  } from "./index";

const initialState: Store.States.FavoriteProject = {
	favoritesProjects: [],
};

export const favoriteProjectReducer = createReducer<
	Store.States.FavoriteProject,
	TAccountActions
>(initialState, {
    [StoreActionKey.SAVE_FAVORITE_PROJECT]: (state, action) => {
        return {
            ...state,
            favoritesProjects: action.payload.favoritesProjects,
        };
    },
});
