import { Reset } from './../../store/account/types'
import { loginInReq, confirmPasswordReq, registrationInReq } from '@/api/auth'
import { Service } from '@/core/abstract'
import { ResetTokens, SaveTokens } from '@/store/auth/types'
import { ITokenPair, NavGroupKey } from '@/typing'
import { storageService } from '../system'
import { accountService } from './account.services'
import { SetNavGroupAction } from '@/store/navigation'
import { navigateTo } from '@/core/helpers'
import { passwordChange } from '@/modules/auth/config'

import { IRegistrationPayload } from "@/api/auth/interfaces";

export class AuthService extends Service {
  public async signIn(email: string, password: string) {
    const { data: resp } = await loginInReq({ email, password });
    this.saveSession(resp);
  }
  public async confirmChangePassword(email: string, password: string) {
    const { data: resp } = await confirmPasswordReq({ email, password });
    this.saveSession(resp);
  }
  public async saveSession(tokens: ITokenPair) {
    await storageService.set("accessToken", tokens.accessToken);
    await storageService.set("refreshToken", tokens.refreshToken);
    this.dispatch(new SaveTokens(tokens));
    accountService.loadAccount();
  }
  public async removeSession() {
    await storageService.remove("accessToken");
    await storageService.remove("refreshToken");
  }

  public async logOut() {
    this.removeSession();
    this.dispatch(new Reset());
    this.dispatch(new ResetTokens());
    this.dispatch(new SetNavGroupAction(NavGroupKey.Auth));
    storageService.set("viewLookBlockTab", 'list');
    storageService.set('modeViewBlockTab', 'false');
  }

  public async registration(data: IRegistrationPayload) {
    const { data: resp } = await registrationInReq(data);
    this.saveSession(resp);
  }
  public async changePassword() {
    navigateTo(passwordChange);
  }
  public async navigateToAuth() {
    this.dispatch(new SetNavGroupAction(NavGroupKey.Auth));
  }
}

export const authService = new AuthService()
