import { StoreActionKey } from "@/typing/enums/store-action-key.enum";
import { Store } from "@/typing/interfaces/system/store.interface";
import { createReducer } from "@bitalikrty/redux-create-reducer";
import { TSharedActions,  } from "./index";

const initialState: Store.States.Shared = {
	viewLookBlock: 'list',
    modeViewBlock: false,
};

export const sharedReducer = createReducer<
	Store.States.Shared,
	TSharedActions
>(initialState, {
    [StoreActionKey.SAVE_VIEW_LOOK_BLOCK]: (state, action) => {
        return {
            ...state,
            viewLookBlock: action.payload.viewLookBlock,
        };
    },

    [StoreActionKey.SAVE_MODE_VIEW_BLOCK]: (state, action) => {
        return {
            ...state,
            modeViewBlock: action.payload.modeViewBlock,
        };
    },
});
