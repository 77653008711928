import { StoreActionKey } from '@/typing/enums/store-action-key.enum';
import { IUser } from '@/typing/interfaces/domain';
import { Store } from '@/typing/interfaces/system/store.interface';

export class SaveAccount implements Store.Action {
  readonly type = StoreActionKey.SET_ACCOUNT;
  constructor(public readonly payload: { account: IUser }) {}
}
export class SetAccountLoading implements Store.Action {
  readonly type = StoreActionKey.SET_ACCOUNT_LOADING;
  constructor(public readonly payload: boolean) {}
}
export class Reset implements Store.Action {
  readonly type = StoreActionKey.RESET_ACCOUNT;
}

export type TAccountActions = SaveAccount | Reset | SetAccountLoading;
