import { GetPresignedUrlReqResponse } from "./../../services/system/file.service";
import { IPaginationReq } from "./../../typing/interfaces/system/paginations.interface";
import { IGetUsersResponse, IPatchUserResponse } from "./interfaces";
import { http } from "../http.service";
import { IUser } from "@/typing/interfaces/domain";

export const getUsersReq = (params: IPaginationReq) => {
  return http.get<IGetUsersResponse>("/users", { params });
};

export const deleteUsersReq = (payload: IUser) => {
  return http.delete(`/users/${payload.id}`);
};

export const patchUsersReq = (payload: IUser) => {
  return http.patch<IPatchUserResponse>(
    `/users/profile/${payload.id}`,
    payload
  );
};
export const getPresignedUrlReq = (params: any) => {
  return http.get<GetPresignedUrlReqResponse>("/users/avatarUrl-link", {
    params,
  });
};

export const finishUploadReq = (uploadId: string) => {
  return http.put("/users/avatarUrl-finish-upload", { uploadId });
};

export const getUsersProfileReq = (params: number) => {
  return http.get(`/users/profile/${params}`);
};
