import {
  IProjectResponse,
  IGetProjectsResponse,
  IGetProjectDetailedResponse,
  IPatchProjectResponse,
  IPatchContentBlockResponse,
  IPostContentBlockResponse,
  IContentResponse,
  IFavoriteProjectResponses,
  IRecordContentParams,
  GetLinkContenpFileParams,
} from "./interfaces";
import { http } from "../http.service";
import { IPaginationReq } from "@/typing/interfaces/system/paginations.interface";
import {
  IProject,
  IFavoriteProject,
} from "@/typing/interfaces/domain/project.interface";
import { IProjectBlock } from "@/typing/interfaces/domain/project-block.interface";
import { IRecordContents } from "@/typing/interfaces/domain/record-contents.intarface";
import { GetPresignedUrlReqResponse } from "@/services/system/file.service";

export const getProjectsReq = (params: IPaginationReq) => {
  return http.get<IGetProjectsResponse>("/projects", { params });
};

export const getProjectDetailedReq = (payload: number) => {
  return http.get<IGetProjectDetailedResponse>(`/projects/${payload}`);
};
export const storeProjectReq = (payload: IProject) => {
  return http.post<IProjectResponse>("/projects", {
    ...payload,
    deviceName: "d",
  });
};

export const deleteProjectReq = (payload: IProject) => {
  return http.delete(`/projects/${payload.id}`);
};

export const patchProjectReq = (payload: IProject) => {
  return http.patch<IPatchProjectResponse>(`/projects/${payload.id}`, payload);
};

export const patchContentBlockReq = (payload: IProjectBlock) => {
  return http.patch<IPatchContentBlockResponse>(
    `/content-blocks/${payload.id}`,
    payload
  );
};

export const deleteContentBlockReq = (id: number) => {
  return http.delete(`/content-blocks/${id}`);
};

export const postContentBlockReq = (payload: IProjectBlock) => {
  return http.post<IPostContentBlockResponse>(`/content-blocks`, payload);
};

export const getRecordContentsReq = (params: IRecordContentParams) => {
  return http.get<any>(`/record-contents`, { params });
};

export const storeContentsReq = (payload: IRecordContents) => {
  return http.post<number>("/record-contents", {
    ...payload,
  });
};
export const deleteContentsReq = (payload: number) => {
  return http.delete(`/record-contents/${payload}`);
};

export const getUploadProjectUrlReq = (params: any) => {
  return http.get<GetPresignedUrlReqResponse>("/projects/avatarUrl-link", {
    params,
  });
};

export const finishUploadProjectReq = (uploadId: string) => {
  return http.put("/projects/avatarUrl-finish-upload", { uploadId });
};

export const putFavoriteProjectReq = (payload: IFavoriteProject) => {
  return http.put("/projects/favorites", payload);
};

export const getFavoriteProjectsReq = () => {
  return http.get<IFavoriteProjectResponses[]>("/projects/favorites");
};

export const getLinkFileContent = (params: any) => {
  return http.get<GetPresignedUrlReqResponse>(
    "/record-contents/file-content-link",
    { params }
  );
};

export const finishUploadFileContent = (uploadId: string) => {
  return http.put("/record-contents/file-content-finish-upload", { uploadId });
};