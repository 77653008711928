import { UserRole } from '@/typing/enums/users-role.enum';
import { StoreActionKey } from '@/typing/enums/store-action-key.enum';
import { Store } from '@/typing/interfaces/system/store.interface';
import { createReducer } from '@bitalikrty/redux-create-reducer';
import { TAccountActions } from '.';

const initialState: Store.States.Account = {
  info: {
    data: null,
    isLoading: true,
  },
};

export const accountReducer = createReducer<Store.States.Account, TAccountActions>(initialState, {
  [StoreActionKey.SET_ACCOUNT]: (state, action) => {
    return {
      ...state,
      info: {
        data: action.payload.account,
        isLoading: false,
      },
    };
  },

  [StoreActionKey.SET_ACCOUNT_LOADING]: (state, action) => {
    return {
      ...state,
      info: {
        ...state.info,
        isLoading: action.payload,
      },
    };
  },

  [StoreActionKey.RESET_ACCOUNT]: () => {
    return {
      ...initialState,
      info: {
        data: null,
        isLoading: false,
      },
    };
  },
});
