import { NavGroupKey } from "@/typing/enums";
import { Service } from "@/core/abstract";
import { SaveAccount } from "@/store/account";
import { accountReq, patchAccountReq } from "@/api/account";
import { storageService } from "../system";
import { SetNavGroupAction } from "@/store/navigation";
import { IUpdateAccountPayload } from "@/api/account/interfaces";
import { IFile } from "@/typing";
import { presignedUploaderService } from "../system/file.service";
import { finishUploadReq, getPresignedUrlReq } from "@/api/users";
import { favoriteProjectService } from "./favorite-project";
import { blockService } from "./block.service";

export class AccountService extends Service {
  public async init() {
    const accessToken = await storageService.get("accessToken");
    if (accessToken) {
      await accountService.loadAccount();
    } else {
      this.dispatch(new SetNavGroupAction(NavGroupKey.Auth));
    }
  }

  public async loadAccount() {
    try {
      const { data: account } = await accountReq();
      this.dispatch(new SaveAccount({ account }));
      this.dispatch(new SetNavGroupAction(NavGroupKey.Private));
      favoriteProjectService.getFavoritesProjects()
      blockService.initViewContentBlock()
    } catch (error) {
      this.dispatch(new SetNavGroupAction(NavGroupKey.Auth));
    }
  }

  public async patchAccount(payLoad: IUpdateAccountPayload) {
    try {
      await patchAccountReq(payLoad);
      await this.loadAccount();
    } catch (error) {
      this.dispatch(new SetNavGroupAction(NavGroupKey.Auth));
    }
  }

  public async uploadAvatar(avatar: IFile, id: any) {
    try {
      await presignedUploaderService.upload(
        avatar,
        (params: any) => getPresignedUrlReq(params),
        (params: any) => finishUploadReq(params),
        { userId: id }
      );
      await this.loadAccount();
    } catch (error) {
      console.log("account error", error);
    }
  }
}

export const accountService = new AccountService();
